
@font-face {
  font-family: "Chalkboard";
  src: local("Chalkboard"),
   url("./Assets/fonts/Chalkboard-Regular.ttf") format("truetype");
  font-weight: bold;
  }
  @font-face {
    font-family: "Norton";
    src: local("Norton"),
     url("./Assets/fonts/norton/NORTON__.TTF") format("truetype");
    }
  
 @font-face {
  font-family: "321Impact";
  src: local("321Impact"),
    url("./Assets/fonts/321impact.ttf") format('truetype');
  font-weight: bold;
  font-style: normal;
    }

  
 @font-face {
  font-family: "Chalkboy";
  src: local("Chalkboy"),
    url("./Assets/fonts/chalkboy/Chalkboy\ Free\ Trial.woff2") format('woff2'),
    url("./Assets/fonts/chalkboy/Chalkboy\ Free\ Trial.woff") format('woff');
  font-weight: bold;
  font-style: normal;
    }
  

    
.App {
  text-align: center;
  position: relative;
  bottom: 30;
  margin: 0 auto;
  padding: 0 100px;
  box-sizing: border-box;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 0.8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  z-index: 1;
}

.button-font{
  font-family: "Chalkboy";
  color: white;
  font-size: 40px;
  border: none;
}

.button-font-pag{
  font-family: "Chalkboard";
  color: white;
  font-size: 50px;
}
.button-font-gallery{
  font-family: "Chalkboy";
  color: white;
  font-size: 40px;
}
.button-font-menu{
  font-family: "Chalkboy";
  color: white;
  font-size: 30px;
  background-color: transparent;
  border: none;
}


nav ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

nav ul li{
  margin: 0 10px;
}

nav li {
  margin: 0 10px;
}

nav  button {
  background-color: transparent;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
}

.menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  background-color: transparent;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
}

.right-menu {
  background: #000000;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  flex-grow: 1;
}

nav ul li button:hover{
  color: #549F33;
}

.drawer{
  background-color: black;
  display: flexbox;
  color: white;
  justify-content: space-around;
  align-items: center;
  padding-top: 10vh;
  height: 100vh;
  background: #000000;
}

.drawer-opacity{
  opacity: 0.9;
}

.close-position{
  display: flex;
  margin-top: 1vh;
  height: 100%;
}

.list-position{
  padding: 50vh;
}

.logo {
  margin-top: 0px;
  margin-left: 153px;
  width: 365px;
  height: 82px;
  float: left;
}

.right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  flex-grow: 1;
}


/* button Book Now */
.bookNow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  flex-grow: 1;
}


.react-player{
  position: fixed;
  bottom: 0;
  width: 100vw;
  margin: 0;
  padding: 0;
  border: none;
  z-index: 9999;
}


.top-button {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 100px;
  height: 100px;
  background-color: transparent;
  border:0cap;
  color: white;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}


body {
  background: linear-gradient(0deg, rgb(0, 0, 0), rgba(0, 0, 0, 0)),  url('https://images.unsplash.com/photo-1614292264554-7dca1d6466d6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.icon{
  color: white;
  font-size: 10px;
  fill: white;
}
.column{
  display: flexbox;
  align-content: center;
  max-width: 100vw;
}

.whatsapp{
  width: 100%;
  background-color: #549F33;
  color: white;
  font-family: "Chalkboy";
  border-radius: 40px;
}

@media only screen and ( max-width: 767px ){ 
  nav ul {
    display: none;
    justify-content: flex-end;
  }

  .logo{
    width: 203px;
    height: 46px;
    margin-top: 0px;
    margin-left: 0px;
  }

  .hamburger {
    display: flex-end;
    width: 27px;
    height: 18px;
    margin-top: 20px;
    margin-right: 325.4px;

  }

   .right-menu {
    align-items: flex-end;
  }

  .top-button {
    bottom: 80px;
    right: 20px;
    border: 0cap;
  }
  .react-player{
    position: fixed;
  }
  
  body {
    background: linear-gradient(0deg, rgb(0, 0, 0), rgba(0, 0, 0, 0.3)), url('https://lh3.googleusercontent.com/d/1aJz2QlFMdBGSDeI1uXBs30UnO-sXHpF5');
    background-size: cover;
    background-color: #000000;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh;
  }

  .bookNow{
    display: none;
  }

  .menu{
    display: flex-end;
    width: 27px;
    height: 18px;
    padding-top: 5px;
    left: 30px;
  }
  
  .icon{
    height: 40px;
    width: 40px;
  }

  .about-photo{


    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    border-radius: 20px 20px 20px 20px;
    width: 260px;
    height: 187px;
    opacity: 1;
    overflow: hidden;
  }
  .about-photo img {
    width: 100%; 
    height: auto;clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); 
    transform: scale(1); 
    transform-origin: 50% -10% ; 
  }
  
  .flex-component{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .bold{
    font-weight: bolder;
    font-size: 1.6rem;
    color: #FF0800;
  }
  .size{

    font-size: 1.4rem;
    color: #FFFFFF;
  }
  .title-about{
    width: 290px;
    align-content: center;
    text-align: center;
    font: normal normal normal "Norton";
    letter-spacing: 0px;
    opacity: 1;
  }


  

  .text-about{
    top: 28px;
    left: 392px;
    width: 290px;
    height: 1280px;
    text-align: left;
    font: normal normal normal 20px "Norton";
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }

  .view-desktop{
    display: none;
  }
  .view-tablet{
    display: none;
  }
  .view-phone{
    display: block;
  }


}

@media only screen and ( min-width: 768px ) and ( max-width: 1023px ){
  
  nav ul {
    display: flex;
    justify-content: flex-end;
  }

   .hamburger {
    display: none;
  }

 

  .top-button {
    bottom: 90px;
    border: none;
  }
  .menu{
    display: flex;
    right: 50px;

  }
  .right {
    display: none;
  }
  .right-menu{
    display: flex;
  }

  .bookNow{
    display: flex;
    right: 518 px;
    top: 10px;
    width: 225px;
    height: 54px;
  }
  
  .logo {
    margin-top: 0px;
    margin-left: 81px;
    width: 242px;
    height: 54px;
    float: left;
  }
  .icon{
    display: flex;
    width: 40px;
    height: 40px;
  }

  
  .about-photo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    height: 213px;
    border-radius: 20px 20px 20px 20px;
    opacity: 1;
    overflow: hidden;
  }
  .about-photo img {
    width: 100%; /* set the width of the image to 100% of the container */
    height: auto;clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); /* define the visible area of the image using a polygon */
    transform: scale(1); /* scale the image by 1.5 times */
  }

  .title-about{
    text-align: center;
    font: normal normal normal 2rem "Norton";
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }

  .text-about{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 51px;
    text-align:justify;
    margin-left: 10px;
    font: normal normal normal 1.3rem "Norton";
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }

  .bold{
    font-weight: bolder;
    font-size: 1.8rem;
    color: #FF0800;
  }
  .size{
    font-size: 1.6rem;
    color: #FFFFFF;
  }
  
  .space{
    margin-top: 10px;
  }
  .centered{
    margin-top: 15dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .view-phone{
    display: none;
  }
  .view-tablet{
    display: block;
  }
  .view-desktop{
    display: none;
  }

}

@media only screen and ( min-width: 1024px ){ 
  
  .component {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }
  .component-row {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    flex-direction: row;
  }
  .component h1 {
    text-align: left;
    font: normal normal normal 6rem Chalkboy;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }


  .flex-component{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .top-button {
    bottom: 90px;
    border: 0cap;
  }

  .bookNow{
    display: none;
  }

  .menu{
    display: none;
  }

  .right-menu{
    display: none;
  }
  .drawer{
    display: none;
  }
  .drawer-opacity{
    display: none;
  }

  .icon{
    height: 64px;
    width: 74px;
  }

  .about-photo{
    margin-top: 20px;
    width: 536px;
    height: 696px;
    border-radius: 150px 20px 20px 150px;
    opacity: 1;
    overflow: hidden;
  }
  .about-photo img {
   
    width: 100%; /* set the width of the image to 100% of the container */
    height: auto; /* maintain the aspect ratio of the image */
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); /* define the visible area of the image using a polygon */
    transform: scale(1.7) translateX(6%) translateY(13%); /* scale the image by 1.5 times and translate it to the left by half its width */
    transform-origin: center center; /* set the origin of transformation to the center of the image */
}
  .bold{
    font-weight: bolder;
    font-size: 1.8rem;
    color: #FF0800;
  }
  .size{
    font-size: 1.6rem;
    color: #FFFFFF;
  }
  .title-p{
    font: normal normal normal  "Norton";
    opacity: 1;
  }
  .title-about{
    text-align: center;
    font: normal normal normal  "Norton";
    letter-spacing: 0px;
    color: #FFFFFF;
    font-size: 1.8rem;
    opacity: 1;
  }

  .text-about{
    margin-top: 85px;
    width: 60dvw;
    text-align: left;
    margin-left: 10px;
    font: normal normal normal 1.3rem "Norton";
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }

  .title-about-2{
    align-content: center;
    text-align: center;
    font-family: "321Impact";
    font-size: 8rem;
    margin-top: -3vh;
    margin-bottom: 0vh;
    color: #549F33;
    opacity: .7;
  }

  .centered{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .lefty{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .view-phone{
    display: none;
  }
  .view-tablet{
    display: none;
  }
  .view-desktop{
    margin-top: 14vh;
    display: block;
  }



}
.media{
  width: 85vw;
}
.media-photo{
  width: 85vw;
}
.media-video{
  width: 85vw;
}
.media-event{
  width: 85vw;
}
.media-event-2{
  width: 85vw;
}
.align-left{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.flex{
  display: flex;
  flex-wrap: wrap;
}
.title{
  font-family: "Chalkboy";
  color: white;
  font-size: 50px;
  align-content: flex-start;
  width: fit-content;
}

.space{
  margin-top:2vw
}


.container{
  display: flex;
  flex-wrap: wrap;
}

.book-now {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.column-book{
  flex: 1 1 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.find-me {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.contact-form {
  flex: 1 1 50%;
  padding: 20px;
  max-width: 290px;
  min-width: 270px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  .column-book {
    flex-basis: 100%;
  }
}

.book-center{
  display: flex;
  align-content: center;
  justify-content: center;
}


.book-title-1{
  font-family: "Chalkboy";
  color: white;
  font-size: 8vh;
  min-width: 300px;
}

.book-title-2{
  font-family: "Chalkboy";
  color: white;
  font-size: 3rem;
  align-content: flex-start;
  width: fit-content;
}

.fast-contact-button{
  margin-top: 4vh;
  min-width: 290px;
  height: 104px;
  border: 10px solid #559F33 ;
  border-radius: 30px;
  opacity: 1;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content:center;
  flex-direction: row;
}

.fast-contact{
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.fast-contact-icon{
  width: 50px;
  height: 50px;
  margin-left: 10px;
  fill: #559F33;
}
.fast-contact-text{
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 10px;
  font-family: "Chalkboy";
  display: flex;
  
  font-size: 2rem;
  color: #559F33;
}
.contact-number{
  width: 300px;
  margin-top: 20px;
  font-family: "Chalkboy";
  font-size: 2.6rem;
  color: #549F33;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content:center;
  flex-direction: column;
}

.icon-view{
  margin-top: 20px;
  flex-direction: row;
}

.icon-contact{
  height: 40px;
  width: 40px;
  fill: white;
}


input{
  margin-top: 10px;
  font-family: "Chalkboard";
  font-size: 25px;
  color: black;
  text-align: center;
  height: 40px;
  max-width: 300px;
  min-width: 300px;
  border-radius: 20px;
}

input::placeholder{
  text-align: center;
  font-family: "Chalkboy";
  font-size: 30px;
  color: black;
}

select{
  margin-top: 10px;
  height: 40px;
  min-width: 300px;
  max-width: 300px;
  font-family: "Chalkboy";
  text-align:center;
  border-radius: 16px;
  font-size: 30px;
  color: black;
}


select::placeholder{
  text-align: right;
}

.input-mesaj{
  margin-top: 10px;
  font-family: "Norton";
  font-size: 20px;
  color: black;
  text-align: left;
  height: 20px;
  width: 300px;
  border-radius: 20px;
  height: 160px;

}

.input-mesaj::placeholder{
  font-family: "Chalkboy";
  text-align: center;
  font-size: 40px;
}

.input-data input::placeholder {
  font-size: 30px;
  font-weight: bold;
  color: black;
}

.input-data{
  margin-top: 10px;
  height: 60px;
  width: 300px;
  border-radius: 20px;
  color: black;
}


.book-button{
  margin-top: 10px;
  width: 300px;
  height: 40px;
  font-size: 2.5rem;
  font-family: "Chalkboy";
  color: white;
  background-color: #549F33;
  border-radius: 20px;
  border: none;
}

.upcoming-title{
  font-family: "Chalkboy";
  color: white;
  font-size: 8vh;
  min-width: 290px;
}

.upcoming-center{
  margin-top: 10vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.upcoming-flex{
  margin-top: 10vh;
  flex: 1 1 100%;
  flex-wrap: wrap;
  justify-content: center ;
  align-items: center;
  display: flex;
}

@media only screen and ( max-width: 767px ){ 
  .select-serv{
    border-radius: 20px;
    min-width: 307px;
    max-width: 307px;
    height: 50px;
    text-align: center;
  }
  .upcoming-div{
    margin-bottom: 120px;
  }
  .isPhone{
    display: flex;
    color: white;
    font-family: "Norton";
  }
  .IsPhone{
    display: flex;
  }
  .notPhone{
    display: none;
  }
  .input-data{
    width: 305px;
  }


  .isDesktop{
    display: none;
  }

.media-friend{
  width: auto;
  margin-bottom: 20vh;
}
  
}
@media  screen and ( min-width: 768px ) and  (max-width: 1023px){
  .upcoming-div{
    margin-left: 5vw;
    margin-bottom: 120px;
  }
  .isPhone{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: "Norton";
  }
  .IsPhone{
    display: none;

  }
  .notPhone{
    display: flex;
  }
  .isDesktop{
    display: none;
  }

}
@media  screen and (min-width: 1024px){
  .upcoming-div{
    margin-left: 5vw;
    margin-bottom: 120px;
  }
  .book-now{
  height: 500px;
  }
  .isDesktop{
    display: flex;
    opacity: .7;
  }

  .IsPhone{
    display: none;
  }
  .notPhone{
    display: flex;
  }

  .isPhone{
    display: none;
  }
  
}

.upcoming-div{
  width: 300px;
  height: 380px;
  background: black 0% 0% no-repeat padding-box;
  border-radius: 40px;
  border: 1px solid white;
  opacity: 1;
}

.upcoming-div-small{
  margin-top: -80px;
  margin-left: -60px;
  width: 240px;
  height: 340px;
  background: black 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 40px;
  object-fit:contain;
  opacity: 1;
}


.upcoming-div-row{
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
}
.upcoming-not-found{
  max-width: 300px;
  max-height: 60px;
  color: white;
  text-align: center;
  font-size: 20px;
  font-family: "Norton";
}
.upcoming-div-name{
  max-width: 200px;
  max-height: 60px;
  color: white;
  text-align: left;
  font-size: 20px;
  font-family: "Norton";
}
.upcoming-div-location{
  max-width: 300px;
  max-height: 60px;
  color: white;
  text-align:left;
  font-size: 20px;
  margin-left: 10px;
  margin-top: 20px;
  font-family: "Norton";
}
.upcoming-div-date{
  max-width: 1500px;
  max-height: 60px;
  color: white;
  text-align: right;
  font-size: 20px;
  margin-left: 20px;
  font-family: "Norton";
}

.upcoming-button{
  border: none;
  background-color: transparent;
  color: white;
  text-align:left;
  font-size: 20px;
  font-family: "Norton";
}

.upcoming-button:hover{
  color: #549F33;
}



.space-test{
  margin-top: 20vh;
}
.media-friend{
  margin-top: 5vh;
  width: 100%;
  align-items: center;
}


.friends-title{
  font-family: "Chalkboy";
  color: white;
  font-size: 8vh;
  min-width: 200px;
}

.friends-center{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.gdpr{
  margin-top: 5vh;
  color: white;
  font-family: "Norton";
  max-width: 300px;
  text-align: center;
}